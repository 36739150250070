import React, { useContext, useEffect, useState } from 'react';
import Player from 'components/Table/Player';
import TournamentContext from 'context/TournamentContext';
import getButtonPositionIndex from 'utilis/getButtonPositionIndex';
import TablePots from 'components/Table/TablePots';
import AuthContext from 'context/AuthContext';
import getOrderedPlayers from 'utilis/getOrderedPlayers';
import positionsOnTable from 'constants/positionsOnTable';
import TopicAndQuestion from 'components/Table/TopicAndQuestion';
import useIsOwner from 'hooks/useIsOwner';
import TopToolbar from 'components/TopToolbar/TopToolbar';
import UploadQuestionsForm from 'components/Forms/UploadQuestionsForm';
import UploadedQuestionsContext from 'context/UploadedQuestionsContext';

function Table() {
  const { tournament } = useContext(TournamentContext);
  const { players } = tournament;
  const { user } = useContext(AuthContext);

  const isOwner = useIsOwner();
  const [buttonPositionIndex, setButtonPositionIndex] = useState();
  const [orderedPlayers, setOrderedPlayers] = useState(players);

  const [showUploadQuestionsMenu, setShowUploadQuestionsMenu] = useState(false);

  const {
    questions,
    extractQuestionsFromText,
  } = useContext(UploadedQuestionsContext);

  const positionOnTableIndex = players.length <= 1 ? 0 : players.length - 2;

  useEffect(() => {
    const orderedPlayers = getOrderedPlayers(players, user);
    const buttonIndex = getButtonPositionIndex(orderedPlayers);

    setButtonPositionIndex(buttonIndex);
    setOrderedPlayers(orderedPlayers);
  }, [players]);

  const onUploadQuestionsSave = (text) => {
    extractQuestionsFromText(text);
    setShowUploadQuestionsMenu(false);
  };

  return (
    <div className="table_holder">
      {/* {isOwner && (
      <TopToolbar
        setShowUploadQuestionsMenu={setShowUploadQuestionsMenu}
      />
      )} */}
      <UploadQuestionsForm
        defaultValues={questions}
        onClose={() => { setShowUploadQuestionsMenu(false); }}
        onSave={onUploadQuestionsSave}
        open={showUploadQuestionsMenu}
      />
      <TopicAndQuestion />
      <div className="table">
        <div className="dealer">
          <p>{tournament.owner.username}</p>
          <img src="/images/avatars/dealer.png" alt="dealer" />
        </div>
        <TablePots tournament={tournament} />
        {
            orderedPlayers !== undefined && orderedPlayers.map((player, index) => (
              <Player
                player={player}
                index={index}
                key={player.id}
                playerPosition={positionsOnTable[positionOnTableIndex][index]}
                isButtonPosition={index === buttonPositionIndex}
              />
            ))
        }
      </div>
    </div>
  );
}

export default Table;
