const TOURNAMENT_STATES = {
  STANDARD_QUESTION_NUMBER_CHOOSING: 1,
  TOPIC_ANNOUNCEMENT: 2,
  FIRST_BETTING: 3,
  QUESTION_ANNOUNCEMENT: 4,
  RIGHT_QUESTION_CHECKING: 5,
  SECOND_BETTING: 6,
  RESULTS_ANNOUNCEMENT: 7,
  TIEBREAK_QUESTION_NUMBER_CHOOSING: 8,
  TIEBREAK_QUESTION_ANNOUNCEMENT: 9,
  TIEBREAK_QUESTION_ANSWERING: 10,
};

export default TOURNAMENT_STATES;
