import React from 'react';
import LoadingTournaments from 'components/Loading/LoadingTournaments';
import useTournamentStats from 'hooks/useTournamentStats';
import {
  Box,
  Paper, Stack, Typography,
} from '@mui/material';
import TournamentListItem from './TournamentListItem';

function TournamentStats({ tournamentsToShow }) {
  const {
    ownedByMeTournaments,
    tournamentWhereIPlay,
    everyOtherTournament,
    isLoading,
    error,
  } = useTournamentStats();

  const noTournamentsFound = (
    <Stack sx={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Typography textAlign="center" mt={5} fontWeight={700} fontSize="20px">Няма налични турнири</Typography>
    </Stack>
  );

  const tournaments = {
    owner: ownedByMeTournaments,
    player: tournamentWhereIPlay,
    not_in_game: everyOtherTournament,
  };

  return (
    <>
      {error
      && (
      <Typography
        sx={{
          color: 'red',
          fontWeight: 600,
          textAlign: 'center',
          mt: 6,
        }}
      >
        {error}
      </Typography>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: { md: 8, sx: 0 }, overflowY: 'auto', flexGrow: 1, mt: 2,
        }}
      >

        {isLoading && <LoadingTournaments />}
        {!error && tournaments[tournamentsToShow].length === 0 && !isLoading && noTournamentsFound}
        {
          tournaments[tournamentsToShow].length > 0
          && (
          <Box>
            {
            tournaments[tournamentsToShow].map(
              (tournament) => (
                <TournamentListItem
                  tournament={tournament}
                  key={tournament.uniqueId}
                />
              ),
            )
          }
          </Box>
          )
        }
      </Paper>
    </>
  );
}

export default TournamentStats;
