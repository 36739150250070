import React, { useContext } from 'react';
import TournamentContext from 'context/TournamentContext';
import useTimer from 'hooks/useTimer';
import { Typography } from '@mui/material';

function TimeLeftToAnswerProgress() {
  const { tournament } = useContext(TournamentContext);
  const { players } = tournament;

  const { time } = useTimer({
    initialTime: players[0].secondsLeft,
  });

  return (
    <Typography textAlign="center">
      Остават
      {' '}
      {time > 0 ? Math.floor(time) : 0}
      {' '}
      секунди за отговор
    </Typography>
  );
}

export default TimeLeftToAnswerProgress;
