import TOURNAMENT_STATES from 'constants/tournamentStates';
import getCurrentPlayerAndHighestBet from 'utilis/getCurrentPlayerAndHighestBet';

const getShowControls = (players, user, owner, stateId) => {
  if (user.id === owner.id) {
    return true;
  }

  if (stateId === TOURNAMENT_STATES.TOPIC_ANNOUNCEMENT
      || stateId === TOURNAMENT_STATES.QUESTION_ANNOUNCEMENT
      || stateId === TOURNAMENT_STATES.TIEBREAK_QUESTION_ANNOUNCEMENT
  ) {
    return false;
  }

  const myPlayer = players.find((player) => player.userId === user.id);

  if (!myPlayer) {
    return false;
  }

  if (myPlayer.inPlay === 0 || myPlayer.inPlay === false) {
    return false;
  }

  if ((stateId === TOURNAMENT_STATES.RIGHT_QUESTION_CHECKING
     || stateId === TOURNAMENT_STATES.TIEBREAK_QUESTION_ANSWERING)
       && (myPlayer.inHand === 1 || myPlayer.inHand === true)
       && (myPlayer.inPlay === 1 || myPlayer.inPlay === true)
  ) {
    return true;
  }

  if (stateId === TOURNAMENT_STATES.STANDARD_QUESTION_NUMBER_CHOOSING
      && (myPlayer.isBigBlind === true || myPlayer.isBigBlind === 1)
  ) {
    return true;
  }

  if (
    stateId === TOURNAMENT_STATES.STANDARD_QUESTION_NUMBER_CHOOSING
      && (myPlayer.isBigBlind === false || myPlayer.isBigBlind === 0)
  ) {
    return false;
  }

  const lastPlayerInSecondHand = JSON.parse(localStorage.getItem('lastPlayerInSecondHand'));

  if (stateId === TOURNAMENT_STATES.TIEBREAK_QUESTION_NUMBER_CHOOSING
    && Number(myPlayer.userId) === Number(lastPlayerInSecondHand.userId)
  ) {
    return true;
  }

  if (
    stateId === TOURNAMENT_STATES.TIEBREAK_QUESTION_NUMBER_CHOOSING
    && Number(myPlayer.userId) !== Number(lastPlayerInSecondHand.userId)
  ) {
    return false;
  }

  const { currentPlayer } = getCurrentPlayerAndHighestBet(players);
  if (currentPlayer?.userId === myPlayer?.userId) {
    return true;
  }

  return false;
};

export default getShowControls;
