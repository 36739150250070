import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { USER_URL, LOGIN_URL } from 'constants/apiRoutes';
import convertSnakeToCamelCase from 'utilis/snakeToCamelCase';
import useFetch from 'hooks/useFetch';

const useAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  const { fetchData } = useFetch(USER_URL, {
    fetchOnMount: false,
    onSuccess: (data) => {
      const { user } = convertSnakeToCamelCase(data);
      setUser(user);
    },
    onError: (error, status) => {
      if (status === 401) {
        /* eslint-disable no-use-before-define */
        logOut();
        navigate('/');
      } else {
        navigate('/404');
      }
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const { fetchData: logOutRequest } = useFetch(LOGIN_URL, {
    fetchOnMount: false,
    method: 'DELETE',
  });

  const logIn = (user) => {
    setUser(user);
    localStorage.setItem('isLogged', JSON.stringify(true));
  };

  const logOut = () => {
    localStorage.removeItem('isLogged');
    setUser(null);
    logOutRequest();
  };

  useEffect(() => {
    const isLogged = JSON.parse(localStorage.getItem('isLogged'));
    if (isLogged === null || isLogged === undefined || isLogged === false) {
      setUser(null);
      setIsLoading(false);
      return;
    }

    fetchData();
  }, []);

  return {
    user, logIn, logOut, isLoading,
  };
};

export default useAuth;
