import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TOURNAMENT_STATE_VALUES, STARTING_LEVEL_VALUES } from 'constants/tournamentDataConstants';
import useFetch from 'hooks/useFetch';
import { TOURNAMENT_URL } from 'constants/apiRoutes';
import errorToast from 'components/toasts/errorToast';

const useTournamentForm = () => {
  const navigate = useNavigate();

  const [tournamentData, setTournamentData] = useState({
    numPlayers: 3,
    tournamentFormat: TOURNAMENT_STATE_VALUES[0].value,
    startingLevel: STARTING_LEVEL_VALUES[0].value,
    questionsPerLevel: 2,
    hasAnte: false,
    coinsAtStart: 100,
    lifeTime: 45,
    numStandartQuestions: 50,
    numTiebrakeQeustions: 20,
  });

  const {
    isLoading,
    fetchData,
  } = useFetch(TOURNAMENT_URL, {
    fetchOnMount: false,
    method: 'POST',
    onSuccess: (data) => {
      navigate(`/tournament/${data}`);
    },
    onError: (message) => {
      errorToast(message);
    },
  });

  const updateTournamentData = (e) => {
    const { name, value, type } = e.target;

    switch (type) {
      case 'checkbox':
        setTournamentData((prevTournamentData) => ({
          ...prevTournamentData,
          [name]: !tournamentData[name],
        }));
        break;
      case 'number':
        setTournamentData((prevTournamentData) => ({
          ...prevTournamentData,
          [name]: Number(value),
        }));
        break;
      default: {
        // for select fields
        setTournamentData((prevTournamentData) => ({
          ...prevTournamentData,
          [name]: value,
        }));
        break;
      }
    }
  };

  const submitTournamentData = (e) => {
    e.preventDefault();
    fetchData({
      tournament_format_id: tournamentData.tournamentFormat,
      stack: tournamentData.coinsAtStart,
      starting_level: tournamentData.startingLevel,
      questions_per_level: tournamentData.questionsPerLevel,
      number_of_players: tournamentData.numPlayers,
      has_ante: tournamentData.hasAnte,
      life_time: tournamentData.lifeTime,
      num_standard_questions: tournamentData.numStandartQuestions,
      num_tiebreak_questions: tournamentData.numTiebrakeQeustions,
    });
  };

  return {
    tournamentData, updateTournamentData, submitTournamentData, isLoading,
  };
};

export default useTournamentForm;
